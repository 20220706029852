const Administrator = () => import("@/views/admin/administrator/index.vue")
const FormAdministrator = () => import("@/views/admin/administrator/form.vue")
const ViewAdministrator = () => import("@/views/admin/administrator/view.vue")

export default {
    path: "/admin",
    name: "admin",
    meta: { title: "Administrator Management", authRequired: true },
    children: [
        {
            path: "",
            name: "administrator-management-index",
            component: Administrator,
            meta: { title: "Administrator Management", description: "The most important info highlights",authRequired: true },
        },
        {
            path: "create",
            name: "administrator-management-create",
            component: FormAdministrator,
            meta: { title: "Tambah Admin", description: "Administrator Management - Tambah Admin",authRequired: true },
        },
        {
            path: "edit/:id",
            name: "administrator-management-edit",
            component: FormAdministrator,
            meta: { title: "Edit Admin", description: "Administrator Management - Edit Admin",authRequired: true },
        },
        {
            path: "view/:id",
            name: "administrator-management-view",
            component: ViewAdministrator,
            meta: { title: "Detail Admin", description: "Administrator Management - Detail Admin",authRequired: true },
        },
    ]
}